import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import Modal from '../../molecules/Modal'

// Styles
import { blackWrapper } from './styles.module.scss'

const Guests: React.FC = () => {
  return (
    <>
      <div className={`block-wrapper ${blackWrapper}`}>
        <div className="container relative">
          <Modal />
        </div>
      </div>
    </>
  )
}

export default Guests
