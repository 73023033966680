import React from 'react'

// Styles
import {
  modalTitle,
  modalClose,
  modalContainer,
  modalBackground,
} from './styles.module.scss'

// Data
import { guests } from './data'
import ModalCard from '../../atoms/GuestCard'

const Modal: React.FC = () => {
  return (
    <div className={modalBackground}>
      <h2 className={`${modalTitle} font-subscriber`}>Invitados</h2>
      {guests.map((card) => (
        <ModalCard card={card} key={card.title} />
      ))}
    </div>
  )
}

export default Modal
