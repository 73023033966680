export const guests = [
  {
    img: 'https://ficcofestival.com/img/guests/Delius.png',
    title: 'Delius ',
    description: `Delius (María Delia Lozupone), nació en 1974 en Buenos Aires. Es autora de historietas, dibujante, diseñadora gráfica y docente (Facultad de Arquitectura, Diseño y Urbanismo, UBA). Trabajo de manera independiente y también para editoriales desde 1996. Fue alumna de Alberto Breccia. Forma parte del colectivo internacional de mujeres historietistas “Chicks on Comics”, con el que ha obtenido importantes premios.

      Delius es una dibujante con mucho oficio en la docencia, y brindará un masterclass virtual durante FICCO 2022 sobre herramientas que ayuden a los autores de historietas a trabajar la secuencia narrativa, tanto desde el aspecto visual como desde el guión. Ordenar no sólo las ideas, sino también plasmarlas en un orden fluido y atractivo.`,
    activity:
      'La actividad será el viernes 20 de mayo de 5 a 7 pm, transmitida en el auditorio principal de la biblioteca Virgilio Barco, en Bogotá.    ',
  },
  {
    img: 'https://ficcofestival.com/img/guests/SarahLeavitt.png',
    title: 'Sarah Leavitt',
    description: `El primer libro de Sarah Leavitt, "Tangles: A Story About Alzheimer's, My Mother, and Me", se publicó en Canadá e internacionalmente y se tradujo al francés, alemán y coreano. Tangles fue el primer cómic en ser nominado para un Writers' Trust Award y se ha convertido en un trabajo ampliamente estudiado en el creciente campo de los cómics y la medicina. Se está desarrollando un largometraje de animación de Tangles con el estudio de animación Giant Ant y una importante productora estadounidense.

    El segundo libro de Sarah,"Agnes, Murderess", ganó un premio Vine de literatura judía canadiense (ficción) y un premio Alberta Book Publishers (ficción especulativa). Agnes fue finalista de los dos premios canadienses de historietas, los premios Doug Wright y los premios Joe Shuster. Sarah también ha publicado historietas cortas en revistas y antologías, así como autopublicación de su trabajo en revistas impresas y en línea.
    
    Sarah ha estado desarrollando e impartiendo clases de cómics en la Escuela de Escritura Creativa de la UBC desde 2012; a partir del 1 de julio de 2021, se convirtió en la primera Profesora Asistente de Formas Gráficas de la escuela, y para FICCO 2022 realizará una charla virtual sobre su vida y su experiencia en el noveno arte.`,
    activity:
      'La actividad será el sábado 21 de mayo: 3 a 5 pm, transmitida en el auditorio principal de la biblioteca Virgilio Barco, en Bogotá.',
  },
  {
    img: 'https://ficcofestival.com/img/guests/ReneeNault.png',
    title: 'Renée Nault',
    description: `Renée Nault es una artista, ilustradora y novelista gráfica canadiense. Es conocida por sus vívidas ilustraciones en tinta y acuarela, y se encargó de la adaptación en novela gráfica de: “Handmaid’s Tale”.

    Nault nació en Vancouver y luego se mudó a Victoria. Al crecer cerca del océano, se vio influenciada por la lánguida ingravidez y los colores brillantes de los paisajes submarinos. Más tarde conectó estas ideas con el "mundo flotante" de los estampados japoneses Ukiyo-e que le interesaron por sus patrones y formas repetitivas. A esto aporta un toque muy personal, un simbolismo críptico y un oscuro sentido del humor. A Renee le encanta viajar y ha pasado largos períodos de tiempo en Europa, Asia y África. 
    
    En 2019, se lanzó la adaptación de la novela gráfica de Nault del clásico distópico de Margaret Atwood, The Handmaid's Tale (El Cuento de la Criada). El libro recibió elogios de la crítica y rápidamente se convirtió en un éxito de ventas, con varias nuevas ediciones en idiomas internacionales.
    
    Esta obra trata sobre un futuro distópico donde los desastres ambientales y la disminución de las tasas de natalidad han llevado a una Segunda Guerra Civil Estadounidense. El resultado es el surgimiento de la República de Gilead, un régimen totalitario que impone roles sociales rígidos y esclaviza a las pocas mujeres fértiles que quedan. Offred es uno de ellos, una criada obligada a engendrar hijos para uno de los comandantes de Gilead. Privada de su esposo, de su hijo, de su libertad e incluso de su propio nombre, Defred se aferra a sus recuerdos y a su voluntad de sobrevivir.
    
    Renée realizará para FICCO 2022, la charla virtual: "Bringing Gilead to Life" - ¿Cómo se adaptó The Handmaid's Tale a novela gráfica?"`,
    activity:
      'La actividad será el domingo 22 de mayo: 3 a 5 pm, charla virtual transmitida en el auditorio principal de la biblioteca Virgilio Barco, en Bogotá.',
  },
  {
    img: 'https://ficcofestival.com/img/guests/Fugitivos.jpg',
    title: 'Los Fugitivos',
    description: `Mario Mendoza, Keco Olano, Heidi Muskus y Gabriel Pedroza presentan la segunda entrega de la fascinante y distópica Saga Mysterion: LOS FUGITIVOS

    Después de las masacres y las perse- cuciones en Kaópolis, los discípulos del sensei Issa deben huir de las autoridades de la ciudad.
    
    Tras la cruel ejecución de Issa, su cuerpo desaparece de la morgue de Kaópolis. Las autoridades creen que sus discípulos lo robaron y lo ocultan para provocar un alzamiento de la población. Los discípulos, a su vez, piensan que los asesinos no quieren entregárselo por crueldad.
    
    Esa encrucijada determina las acciones de los protagonistas. Betania es la única que sabe que Issa, su maestro y amante, vive aún, pero ha huido a la India. Pronto la represión que se desata contra sus seguidores y sus familias la lanzará al exilio a ella y a sus compañeros: Simón, Andrés, Juan y Salomón. Cada uno tomará rumbos diferentes y en medio del caos reinante proclamarán que el fin de la humanidad está cada vez más cerca. Betania será la narradora de esta parte de la historia.`,
    activity:
      'La actividad será el sábado 21 de mayo: 6 a 7 pm, en el auditorio principal de la biblioteca Virgilio Barco, en Bogotá.',
    extra:
      'Luego se realizará la firma de libros de 7 PM a 8 PM. Sólo se firmarán libros de la obra gráfica.',
  },
]
