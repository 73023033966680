import React from 'react'

// Styles
import {
  cardImg,
  cardText,
  cardTitle,
  cardExtra,
  cardLayout,
  cardActivity,
  cardDescription,
} from './styles.module.scss'

interface Props {
  card: {
    img: string
    title: string
    description: string
    activity: string
    extra?: string
  }
}

const ModalCard: React.FC<Props> = ({ card }) => {
  return (
    <div className={cardLayout}>
      <div className={cardText}>
        <p className={`${cardTitle} font-subscriber`}>{card.title}</p>
        <img src={card.img} className={cardImg} />
        <p className={`${cardDescription} font-avenir`}>
          {card.description}
        </p>
        <p className={`${cardActivity} font-subscriber`}>{card.activity}</p>
        {card.extra && (
          <p className={`${cardExtra} font-subscriber`}>{card.extra}</p>
        )}
      </div>
    </div>
  )
}

export default ModalCard
